import classes from "./Modal.module.scss";

import { useDispatch } from "react-redux";
import { modalActions } from "../../../store/modal-slice";

const Modal = (props) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    !props.closeDisabled && dispatch(modalActions.close());
  };
  return (
    <>
      <div className={classes.backdrop} onClick={closeModal} />
      <div className={classes.modal}>
        <header className={classes.header}>
          <h5>{props.title}</h5>
          { !props.closeDisabled &&
            <div className={classes.close} onClick={closeModal}>
              <i className="far fa-times-circle"></i>
            </div> }
        </header>
        <div className={classes.content}>{props.children}</div>
      </div>
    </>
  );
};

export default Modal;
