import classes from "./DetaliiInvitatie.module.scss";

import { useState, useEffect, useContext, useCallback } from "react";
import InvitatiileMeleContext from "../../../context/invitatiile-mele-context";
import { fetchAnswerData } from "../../../firebase-invitatii";
import { errorHandler } from "../../../Helpers/Helpers";
import Spinner from "../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../Components/UI/AlertMessage/AlertMessage";
import Control from "./Control/Control";
import Raspunsuri from "./Raspunsuri/Raspunsuri";

const DetaliiInvitatie = () => {
    const ctx = useContext(InvitatiileMeleContext);
    const [isLoading, setIsLoading] = useState(true);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [data, setData] = useState(null);
    const uid = ctx.uid;
    const iid = ctx.selected?.iid;

    const fetchData = useCallback(async () => {
        await fetchAnswerData(uid, iid)
            .then((response) => {
                setData(response);
                setIsLoading(false);
                setAlertError(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setAlertError(true);
                setAlert(errorHandler(err));
            });
    }, [uid, iid]);

    useEffect(() => {
        ctx.selected && fetchData();

    }, [ctx.selected, fetchData]);

    return (
        <div className={classes['detalii-invitatie']}>
            {!ctx.selected &&
                <h5>Selectează de mai sus invitația despre care vrei să vezi detalii.</h5>
            }
            {ctx.selected && isLoading && <Spinner text='Se încarcă detaliile invitației...' />}
            {ctx.selected && !isLoading && alertError && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {ctx.selected && !isLoading && !alertError &&
                <>
                    <Control
                        title={ctx.selected.title}
                        fetchData={fetchData}
                    />
                    <Raspunsuri
                        answers={data.raspunsuri}
                        invitationType={ctx.selected.invitationType}
                    />
                </>
            }
        </div>
    );
}

export default DetaliiInvitatie;