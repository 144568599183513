import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    showModal: false,
    modalTitle: "",
    closeDisabled: false,
    modalContent: null,
  },
  reducers: {
    modal(state, action) {
      state.showModal =
        action.payload.showModal !== undefined
          ? action.payload.showModal
          : state.showModal;
      state.modalTitle = action.payload.modalTitle
        ? action.payload.modalTitle
        : state.modalTitle;
      state.closeDisabled = action.payload.closeDisabled
        ? action.payload.closeDisabled
        : state.closeDisabled;
      state.modalContent = action.payload.modalContent
        ? action.payload.modalContent
        : state.modalContent;
    },
    close(state) {
      state.showModal = false;
      state.modalTitle = "";
      state.closeDisabled = false;
      state.modalContent = null;
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice;
