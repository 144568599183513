import classes from "./FormNunta.module.scss";

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../Components/UI/AlertMessage/AlertMessage";
import ParticipantiNunta from "./ParticipantiNunta/ParticipantiNunta";
import { Button } from "@mui/material";
import TextInput from "../../../../Components/UI/Form/TextInput";
import Radios from "../../../../Components/UI/Form/Radios";
import TextareaInput from "../../../../Components/UI/Form/TextareaInput";
import { saveInvitationAnswer } from "../../../../firebase-invitatii";
import { errorHandler } from "../../../../Helpers/Helpers";
import AddToCalendar from "../AddToCalendar/AddToCalendar";
import FormLogo from "../FormLogo/FormLogo";

const FormNunta = (props) => {
    const { slideData, urlData, slide1Data, live } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(null);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [topButtonsActive, setTopButtonsActive] = useState(false);
    const [showMainContent, setShowMainContent] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [adulti, setAdulti] = useState(null);
    const [copii, setCopii] = useState(null);

    const alreadyAnswered = live && !!localStorage.getItem(`${urlData.invitationId}_aa`);

    const onNumePrenumeChange = (numePrenume) => {
        formik.setFieldValue(
            "numePrenume",
            numePrenume,
            !!formik.touched.numePrenume
        );
        !!numePrenume.trim()
            ? setTopButtonsActive(true)
            : setTopButtonsActive(false);
    };

    const onParticipHandler = () => {
        setShowMainContent(true);
        setShowActions(true);
    };

    const onNuParticipHandler = () => {
        setShowMainContent(false);
        setShowActions(true);
    };

    const onAdultiChanged = (numarAdulti) => {
        formik.setFieldValue("adulti", numarAdulti, !!formik.touched.adulti);
        setAdulti(numarAdulti);
    };

    const onMeniuriAdultiChanged = (meniuriAdulti) => {
        formik.setFieldValue(
            "meniuriAdulti",
            meniuriAdulti,
            !!formik.touched.meniuriAdulti
        );
    };

    const onCopiiChanged = (numarCopii) => {
        formik.setFieldValue("copii", numarCopii, !!formik.touched.copii);
        setCopii(numarCopii);
    };

    const onMeniuriCopiiChanged = (meniuriCopii) => {
        formik.setFieldValue(
            "meniuriCopii",
            meniuriCopii,
            !!formik.touched.meniuriCopii
        );
    };

    const onCazareChanged = (cazare) => {
        formik.setFieldValue("cazare", cazare, !!formik.touched.cazare);
    };

    const onObservatiiChange = (observatii) => {
        formik.setFieldValue("observatii", observatii);
    };

    const formik = useFormik({
        initialValues: {
            numePrenume: "",
            adulti: "",
            meniuriAdulti: [],
            copii: "",
            meniuriCopii: [],
            cazare: "",
            observatii: "",
        },
        validationSchema: Yup.object().shape({
            numePrenume: Yup.string()
                .max(30, "Numele poate conține maxim 30 de caractere!")
                .matches(
                    /^([A-Za-z-ăâîșțĂÂÎȘȚ'" ]*)$/,
                    "Acest câmp acceptă doar diacritice românești!"
                )
                .required("Acest câmp este obligatoriu!"),
            adulti:
                showMainContent &&
                Yup.string().required("Acest câmp este obligatoriu!"),
            meniuriAdulti:
                showMainContent &&
                adulti &&
                Yup.array().length(
                    adulti,
                    "Selectați tipul meniului pentru toți participanții!"
                ),
            copii:
                showMainContent && slideData.copii &&
                Yup.string().required("Acest câmp este obligatoriu!"),
            meniuriCopii:
                showMainContent &&
                copii &&
                Yup.array().length(
                    copii,
                    "Selectați tipul meniului pentru toți copiii!"
                ),
            cazare:
                showMainContent && slideData.cazare &&
                Yup.string().required("Acest câmp este obligatoriu!"),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const formData = {
                nume_prenume: values.numePrenume,
                participa: showMainContent ? "Da" : "Nu",
                adulti: showMainContent && values.adulti,
                meniuri_adulti: showMainContent && values.meniuriAdulti,
                copii: showMainContent && slideData.copii && values.copii,
                meniuri_copii: showMainContent && slideData.copii && values.meniuriCopii,
                cazare: showMainContent && slideData.cazare && values.cazare,
                observatii: showMainContent && slideData.observatii && values.observatii,
            };
            await saveInvitationAnswer(urlData.uid, urlData.invitationId, formData)
                .then(() => {
                    setIsSaving(false);
                    localStorage.setItem(`${urlData.invitationId}_aa`, true);
                    setAlert({
                        severity: "success",
                        title: "Răspuns salvat",
                        description: `Răspunsul dumneavoastră a fost salvat!
                        Vă mulțumim!`,
                    });
                    setSuccess(true);
                })
                .catch((error) => {
                    setAlertError(true);
                    setIsSaving(false);
                    setAlert(errorHandler(error));
                });
        },
    });

    return (
        <div className={classes["nunta-form-wrapper"]}>
            {alreadyAnswered && !isSaving && !success && !alertError && (
                <div className={classes['alert-wrapper']}>
                    <div className={classes.top}>
                        <p>
                            Se pare că ați răspuns deja la invitație. <br/><br/>
                            Pentru orice modificări sau nelămuriri vă rugăm să ne contactați
                            la telefon{" "}
                            <a className={classes.anchor} href={`tel:+4${slide1Data.numarMire}`}>
                                {slide1Data.numarMire}
                            </a>{" "}
                            ({slide1Data.numeMire}) sau{" "}
                            <a className={classes.anchor} href={`tel:+4${slide1Data.numarMireasa}`}>
                                {slide1Data.numarMireasa}
                            </a>{" "}
                            ({slide1Data.numeMireasa}).
                        </p>
                    </div>
                    <div>
                        <FormLogo />
                    </div>
                </div>
            )}
            {isSaving && <Spinner text='Salvare...' />}
            {success && (
                <div className={classes['alert-wrapper']}>
                    <div>
                        <AlertMessage
                            severity={alert.severity}
                            title={alert.title}
                            description={alert.description}
                            refresh={alert.refresh}
                        />
                        <AddToCalendar
                            type="nunta"
                            name={urlData.url}
                            startDate={slide1Data.dataEveniment}
                        />
                    </div>
                    <div>
                        <FormLogo />
                    </div>
                </div>
            )}
            {alertError && (
                <div className={classes['alert-wrapper']}>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                </div>
            )}
            {!alreadyAnswered && !isSaving && !success && !alertError && (
                <form onSubmit={formik.handleSubmit}>
                    <TextInput
                        label='Nume și prenume'
                        value={formik.values.numePrenume}
                        changeHandler={onNumePrenumeChange}
                        error={formik.errors.numePrenume}
                        autoComplete='off'
                    />
                    <div className={classes["top-buttons"]}>
                        <Button
                            color='success'
                            type='button'
                            variant='contained'
                            onClick={onParticipHandler}
                            disabled={!topButtonsActive}
                        >
                            Particip
                        </Button>
                        <Button
                            color='error'
                            type='button'
                            variant='contained'
                            onClick={onNuParticipHandler}
                            disabled={!topButtonsActive}
                        >
                            Nu particip
                        </Button>
                    </div>
                    {showMainContent && (
                        <>
                            <h5>Câte persoane veți participa?</h5>
                            <div className={classes["dynamic-cols"]}>
                                <div className={classes.left}>
                                    <ParticipantiNunta
                                        value={formik.values.adulti}
                                        participantiChanged={onAdultiChanged}
                                        meniuriChanged={onMeniuriAdultiChanged}
                                        errorParticipanti={formik.errors.adulti}
                                        errorMeniuri={
                                            formik.errors.meniuriAdulti
                                        }
                                        plural='Adulți'
                                        singular='adult'
                                    />
                                </div>
                                {slideData.copii && (
                                    <div className={classes.right}>
                                        <ParticipantiNunta
                                            value={formik.values.copii}
                                            participantiChanged={onCopiiChanged}
                                            meniuriChanged={
                                                onMeniuriCopiiChanged
                                            }
                                            errorParticipanti={
                                                formik.errors.copii
                                            }
                                            errorMeniuri={
                                                formik.errors.meniuriCopii
                                            }
                                            plural='Copii'
                                            singular='copil'
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={classes["two-cols-section"]}>
                                {slideData.cazare && (
                                    <div className={classes.left}>
                                        <Radios
                                            label='Aveți nevoie de cazare?'
                                            options={["Da", "Nu"]}
                                            value={formik.values.cazare}
                                            error={formik.errors.cazare}
                                            changeHandler={onCazareChanged}
                                        />
                                    </div>
                                )}
                                {slideData.observatii && (
                                    <div className={classes.right}>
                                        <TextareaInput
                                            label='Observații'
                                            value={formik.values.observatii}
                                            changeHandler={onObservatiiChange}
                                            error={formik.errors.observatii}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {showActions && (
                        <>
                            <div className={classes.actions}>
                                <Button
                                    id={classes.submitButton}
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    disabled={!live}
                                >
                                    Salvează
                                </Button>
                                {!live && <p id={classes.submitDisabled}>Butonul de mai sus nu este funcțional în modul demo!</p>}
                            </div>
                        </>
                    )}
                </form>
            )}
        </div>
    );
};

export default FormNunta;
