import classes from "./ImageRadios.module.scss";

import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { storage } from "../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ImageRadios = (props) => {
    const { addItem, labels, changeHandler, path, text } = props;
    const [customImageURL, setCustomImageURL] = useState(
        addItem?.url ?? ""
    );
    const [selectedRadio, setSelectedRadio] = useState(
        props.default.value ?? labels[0]
    );
    const inputChangeHandler = (event) => {
        setSelectedRadio(event.target.value);
        changeHandler({
            value: event.target.value,
            url:
                event.target.value === addItem?.type
                    ? customImageURL
                    : `/Media/Images/Modele/${path}/${event.target.value}`,
        });
    };
    const selectImage = (event) => {
        event.preventDefault();
        document.getElementById("imageUploadInput").click();
    };
    const uploadImage = (e) => {
        const imageToUpload = e.target.files[0];
        if (e.target.files[0] === null) {
            return;
        }
        const imageRef = ref(
            storage,
            `${addItem.imageId}/${addItem?.type}`
        );
        uploadBytes(imageRef, imageToUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setCustomImageURL(url);
                setTimeout(() => {
                    document.getElementById("customImage").click();
                    changeHandler({
                        value: addItem?.type,
                        url,
                    });
                }, 250);
            });
        });
    };

    let addFileOption = customImageURL ? (
        <div
            className={classes.image}
            style={{ backgroundImage: `url(${customImageURL})` }}
        >
            <div className={classes["edit-icon"]} onClick={selectImage}>
                <Tooltip TransitionComponent={Zoom} title='Schimbă poza'>
                    <i className='fas fa-edit'></i>
                </Tooltip>
            </div>
        </div>
    ) : (
        <div className={classes.image}>
            <div className={classes.add}>
                <Tooltip TransitionComponent={Zoom} title='Încarcă o poză'>
                    <i className='fas fa-plus' onClick={selectImage}></i>
                </Tooltip>
            </div>
        </div>
    );

    return (
        <FormControl className={classes["image-radios-wrapper"]}>
            {text && (
                <FormLabel>
                    <p>{text}</p>
                    {customImageURL && (
                        <p className='error-alert'>
                            <strong>
                                După ce apăsați ”Actualizare”, asigurați-vă că
                                poza încărcată se încadrează corespunzător în
                                pagină.
                            </strong>
                        </p>
                    )}
                </FormLabel>
            )}
            <RadioGroup
                className={classes["image-radios"]}
                row
                name='position'
                defaultValue={props.default.value ?? labels[0]}
                onChange={inputChangeHandler}
            >
                {labels.map((label) => {
                    const labelUrl = require(`../../../../Media/Images/Modele/${path}/${label}.jpg`);
                    return (
                        <FormControlLabel
                            value={label}
                            key={label}
                            control={
                                <Radio checked={label === selectedRadio} />
                            }
                            labelPlacement='top'
                            label={
                                <div
                                    className={classes.image}
                                    style={{
                                        backgroundImage: `url(${labelUrl})`,
                                    }}
                                />
                            }
                        />
                    );
                })}
                {addItem && (
                    <>
                        <FormControlLabel
                            value={addItem?.type}
                            key={addItem?.type}
                            control={
                                <Radio
                                    id='customImage'
                                    checked={
                                        addItem?.type === selectedRadio
                                    }
                                    className={`${
                                        customImageURL || classes["hide-radio"]
                                    }`}
                                />
                            }
                            labelPlacement='top'
                            label={addFileOption}
                        />
                        <input
                            type='file'
                            id='imageUploadInput'
                            style={{ display: "none" }}
                            onChange={uploadImage}
                        />
                    </>
                )}
            </RadioGroup>
        </FormControl>
    );
};

export default ImageRadios;
