import { useState } from "react";
import classes from "./Classes.module.scss";

import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TextInput = (props) => {
    const [showPassword, setShowPassword] = useState(false);

    const inputChangeHandler = (event) => {
        props.changeHandler(event.target.value);
    };

    const handleShowPassword = () => {
        setShowPassword((prevstate) => !prevstate);
    };

    const theEye = {
        endAdornment: (
            <InputAdornment position='end'>
                <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleShowPassword}
                    edge='end'
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        ),
    };

    return (
        <FormControl
            fullWidth={!props.notFullWidth}
            component='fieldset'
            className={classes.formFieldWrapper}
        >
            <TextField
                autoComplete={props.autoComplete === "off" ? "off" : "on"}
                error={!!props.error}
                helperText={props.error}
                className={classes.inputField}
                variant='outlined'
                type={
                    props.secret ? (showPassword ? "text" : "password") : "text"
                }
                label={props.label}
                id={props.label.replaceAll(" ", "")}
                margin='dense'
                onChange={inputChangeHandler}
                value={props.value}
                placeholder={props.placeholder}
                disabled={!!props.disabled}
                InputProps={props.secret && theEye}
                sx={{
                    input: {
                        background: "white",
                        borderRadius: "4px"
                    }
                }}
            />
        </FormControl>
    );
};

export default TextInput;
