import classes from './Result.module.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteInvitation from './DeleteInvitation/DeleteInvitation';

const Result = (props) => {
    const { invData } = props;
    return (
        <div className={classes.result}>
            {!invData && (<p>Caută o invitație folosind unul din câmpurile de mai sus!</p>)}
            {invData && (
                <>
                    <p>Rezultat căutare:</p>
                    <TableContainer component={Paper} sx={{ maxHeight: 'inherit' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID invitație</TableCell>
                                    <TableCell align="center">URL invitație</TableCell>
                                    <TableCell align="center">ID utilizator</TableCell>
                                    <TableCell align="center">Email utilizator</TableCell>
                                    <TableCell align="center">Invitații rămase</TableCell>
                                    <TableCell align="center">Acțiuni</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invData.map((row) => (
                                    <TableRow key={row.iid}>
                                        <TableCell align="center">
                                            {row.iid}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.url}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.uid}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.email}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.invitatii_ramase}
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className={classes['actions-wrapper']}>
                                                <DeleteInvitation
                                                    iid={row.iid}
                                                    handleData={props.handleData}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </div>
    );
}

export default Result;