import { useState, useEffect } from "react";
import { auth, firestore } from "./firebase";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import { saveOrUpdateInvitationData } from "./firebase-invitatii";

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
      return unsub;
    }, 1000);
  }, [currentUser]);
  return currentUser;
};

// Send email confirmation
export const sendEmailConfirmation = (user) => {
  return sendEmailVerification(user);
};
// Login
export const login = (email, password) => {
  try {
    return signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    return error;
  }
};

// Log out
export const logout = () => {
  localStorage.removeItem("ul");
  return signOut(auth);
};

// Sign up
export const signUp = async (email, password, profile, invitationData) => {
  await createUserWithEmailAndPassword(auth, email, password);
  const usersCollection = collection(firestore, "users");
  const userCollection = doc(usersCollection, auth.currentUser.uid);
  await setDoc(userCollection, { profile });
  await sendEmailConfirmation(auth.currentUser);
  if (invitationData.invitationObject) {
    const uid = auth.currentUser.uid;
    await saveOrUpdateInvitationData(uid, invitationData.invitationType, invitationData.url, invitationData.invitationObject, invitationData.pending)
      .then(async () => {
        setTimeout(async () => {
          await logout();
          return;
        }, 500);
      })
      .catch((err) => {
        return new Error(err);
      });
  }
  await logout();
};

// Reset password
export const resetPassword = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

// Profile
export const getUserProfile = async (providedUID = undefined) => {
  try {
    const uidToUse = providedUID?.uid ?? auth.currentUser?.uid;
    const user = doc(firestore, "users", uidToUse);
    const userProfile = await getDoc(user);
    const data = userProfile.data();
    const { first_name, last_name, user_created } = await data.profile;
    localStorage.setItem("ul", true);
    const claims = await auth.currentUser?.getIdTokenResult(true);
    const isAdmin = claims.claims.admin;

    return {
      uid: uidToUse,
      isAdmin,
      profile: {
        email: auth.currentUser.email,
        first_name,
        last_name,
        user_created: user_created.seconds,
      },
    };
  } catch (error) {
    return error;
  }
};
