import classes from "./Spinner.module.scss";
import Logo from "../../../Media/Images/Misc/logo-blue.svg";

const Spinner = (props) => {
  return (
    <div className={classes.spinner}>
      <div className={classes["spinner-wrapper"]}>
        <img
          className={classes.spin}
          src={Logo}
          alt="Logo"
          style={{
            height: props.size ? `${props.size}rem` : "4rem",
            width: props.size ? `${props.size}rem` : "4rem",
          }}
        />
        {props.text && <p>{props.text}</p>}
      </div>
    </div>
  );
};

export default Spinner;
