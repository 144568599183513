import classes from '../SettingsMenu.module.scss';

import { useContext } from "react";
import InvitatiileMeleContext from '../../../../../../context/invitatiile-mele-context';
import MenuItem from '@mui/material/MenuItem';

const EditeazaInvitatie = () => {
    const ctx = useContext(InvitatiileMeleContext);

    const editeazaInvitatie = () => {
        window.location.pathname = `/invitatiile-mele/${ctx.selected.invitationType}/${ctx.selected.iid}`;
    }
    return (
        <MenuItem
            className={classes['menu-item']}
            onClick={editeazaInvitatie}
        >
            <i className={`fas fa-edit ${classes.icon}`}></i> Editează invitația
        </MenuItem>
    );
}

export default EditeazaInvitatie;