import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/auth-slice";
import { useAuth, getUserProfile } from "./firebase-auth";
import Modal from "./Components/UI/Modal/Modal";
import { modalActions } from "./store/modal-slice";
import TesterCheck from "./Components/Auth/TesterCheck/TesterCheck";
import Navigation from "./Components/Navigation/Navigation";
import Acasa from "./Pages/Acasa";
import Despre from "./Pages/Despre";
import Links from "./Pages/Invitatii/Links/Links";
import InvitatieNunta1 from "./Pages/Invitatii/Nunta/InvitatieNunta1/InvitatieNunta1";
import InvitatieBotez1 from "./Pages/Invitatii/Botez/InvitatieBotez1/InvitatieBotez1";
import InvitatieNuntaBotez1 from "./Pages/Invitatii/NuntaBotez/InvitatieNuntaBotez1/InvitatieNuntaBotez1";
import InvitatieAniversare1 from "./Pages/Invitatii/Aniversare/InvitatieAniversare1/InvitatieAniversare1";
import Edit from "./Pages/InvitatiileMele/DetaliiInvitatie/Control/SettingsMenu/EditeazaInvitatie/Edit/Edit";
import Contact from "./Pages/Contact";
import NotFound from "./Pages/NotFound";
import InvitatiileMele from "./Pages/InvitatiileMele/InvitatiileMele";
import Admin from "./Pages/Admin/Admin";
import { PendingInvitations } from "./Helpers/Pending";

const App = () => {
    const [showNav, setShowNav] = useState(true);

    const dispatch = useDispatch();
    const ul = localStorage.getItem("ul");
    const ta = JSON.parse(sessionStorage.getItem("ta")) ?? false;
    const userData = useAuth();
    
    useEffect(() => {
        if (userData) {
            const updateProfile = async () => {
                const profile = await getUserProfile(userData);
                dispatch(authActions.loginUser(profile));
            };
            updateProfile();
        }
    }, [userData, dispatch]);

    useEffect(() => {
        window.location.pathname === "/invitatii/nunta/n1" && setShowNav(false);
        window.location.pathname === "/invitatii/botez/b1" && setShowNav(false);
        window.location.pathname === "/invitatii/nunta-botez/nb1" && setShowNav(false);
        window.location.pathname === "/invitatii/aniversare/a1" && setShowNav(false);
        window.location.href.indexOf("/i/") > 0 && setShowNav(false);
        // Comment the below block when in prod
        if (window.location.href.indexOf("/i/") > 0) {
            return;
        }
        else if (!ta) {
            dispatch(
                modalActions.modal({
                    showModal: true,
                    modalTitle: "Site în construcție",
                    closeDisabled: true,
                    modalContent:
                        <TesterCheck />,
                })
            );
        }
    }, [dispatch, ta]);

    const isLoggedIn = useSelector((state) => state.auth.uid);
    const isAdmin = useSelector((state) => state.auth.isAdmin);
    const showModal = useSelector((state) => state.modal.showModal);
    const modalTitle = useSelector((state) => state.modal.modalTitle);
    const closeDisabled = useSelector((state) => state.modal.closeDisabled);
    const modalContent = useSelector((state) => state.modal.modalContent);

    // When user logs in, check if he has pending invites
    PendingInvitations();

    return (
        <div className="App">
            {showNav && <Navigation isLoggedIn={ul || !!isLoggedIn} isAdmin={!!isLoggedIn && isAdmin} />}
            {showModal && <Modal title={modalTitle} closeDisabled={closeDisabled}>{modalContent}</Modal>}
            <Routes>
                <Route path="/" element={<Acasa />} />
                <Route path="despre-noi" element={<Despre />} />
                <Route
                    path="invitatii/nunta/n1"
                    element={<InvitatieNunta1 isLoggedIn={isLoggedIn} />}
                />
                <Route
                    path="invitatii/botez/b1"
                    element={<InvitatieBotez1 isLoggedIn={isLoggedIn} />}
                />
                <Route
                    path="invitatii/nunta-botez/nb1"
                    element={<InvitatieNuntaBotez1 isLoggedIn={isLoggedIn} />}
                />
                <Route
                    path="invitatii/aniversare/a1"
                    element={<InvitatieAniversare1 isLoggedIn={isLoggedIn} />}
                />
                <Route
                    path="invitatiile-mele"
                    element={(ul || !!isLoggedIn) ?
                        <InvitatiileMele /> :
                        <Navigate to="/" replace={true} />
                    }
                />
                <Route
                    path="admin"
                    element={!!isLoggedIn && isAdmin ?
                        <Admin /> :
                        <Navigate to="/" replace={true} />
                    }
                />
                <Route path="contact" element={<Contact />} />
                <Route path="i/:id" element={<Links />} />
                <Route path="invitatiile-mele/:invType/:iid" element={<Edit />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default App;
