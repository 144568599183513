import { useState } from "react";
import { login, getUserProfile } from "../../../firebase-auth";
import { auth } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth-slice";
import { errorHandler } from "../../../Helpers/Helpers";

import classes from "../Auth.module.scss";
import { Button } from "@mui/material";
import Spinner from "../../UI/Spinner/Spinner";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";
import TextInput from "../../UI/Form/TextInput";

const Login = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState({});

  const dispatch = useDispatch();
  const switchToSignUp = () => {
    props.show("sign-up");
  };
  const switchToForgotPassword = () => {
    props.show("forgot-password");
  };
  const onEmailChange = (email) => {
    formik.setFieldValue("email", email, !!formik.touched.email);
  };

  const onPasswordChange = (password) => {
    formik.setFieldValue("password", password, !!formik.touched.password);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Introduceți o adresă de email validă")
        .required("Introduceți o adresă de email"),
      password: Yup.string().required("Introduceți o parolă!"),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setIsSaving(true);
      login(values.email, values.password)
        .then(async (data) => {
          if(data.user.emailVerified) {
            localStorage.setItem("ul", true);
            setSuccess(true);
            onAuthStateChanged(auth, (user) => setCurrentUser(user));
            props.close();
            setTimeout(async () => {
              let profile = await getUserProfile(currentUser);
              dispatch(authActions.loginUser(profile));
              props.fromInvitation && props.openModalUrl();
            }, 500);
          } else {
            setAlert(errorHandler({
              code: "auth/not-activated"
            }));
            setSuccess(false);
            setError(true);
          }
        })
        .catch((error) => {
          setAlert(errorHandler(error));
          setSuccess(false);
          setError(true);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
  });

  return (
    <div className={classes.login}>
      {isSaving && <Spinner text="Autentificare..." />}
      {error && (
        <AlertMessage
          severity={alert.severity}
          title={alert.title}
          description={alert.description}
          refresh={alert.refresh}
        />
      )}
      {!isSaving && !success && !error && (
        <form onSubmit={formik.handleSubmit}>
          {!!props.fromInvitation && <p>Pentru a putea continua va trebui să îți faci un cont pe site-ul nostru.<br />Astfel, vei avea acces la răspunsurile celor cărora le vei trimite invitația, toate centralizate într-un singur loc.</p>}
          <p>
            Nu ai cont?{" "}
            <span className="anchor" onClick={switchToSignUp}>
              Creează-ți unul acum!
            </span>
          </p>
          <TextInput
            label="Email"
            changeHandler={onEmailChange}
            error={formik.errors.email}
          />
          <TextInput
            label="Parolă"
            changeHandler={onPasswordChange}
            error={formik.errors.password}
            secret
          />
          <Button id={classes.submitButton} type="submit" variant="contained">
            Autentificare
          </Button>
          <p className="anchor" onClick={switchToForgotPassword}>
            Ai uitat parola?
          </p>
        </form>
      )}
    </div>
  );
};

export default Login;
