import classes from './Control.module.scss';

import SettingsMenu from './SettingsMenu/SettingsMenu';
import Refresh from './Refresh/Refresh';
import RaspunsuriRamase from './RaspunsuriRamase/RaspunsuriRamase';

const Control = (props) => {
    return (
        <div className={classes['control-wrapper']}>
            <div className={classes.title}>
                <h5>{props.title}</h5>
                <Refresh fetchData={props.fetchData} />
                <SettingsMenu />
            </div>
            <div className={classes.control}>
                <RaspunsuriRamase />
            </div>
        </div>
    );
}

export default Control;
