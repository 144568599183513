import classes from "../../../Slides1.module.scss";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import dayjs from "dayjs";
import EditInvitatie from "../../../Components/Edit/Edit";
import FormSlide2 from "./FormSlide2";
import LocationBlock from "../../../Components/LocationBlock/LocationBlock";
import Cookies from "js-cookie";

const Slide2 = (props) => {
    const [initialRender, setInitialRender] = useState(true);
    const [slide2Data, setSlide2Data] = useState({
        cununieCivila: true,
        locatieCununieCivila:
            "Casa Căsătoriilor, Bulevardul Mihai Eminescu, Timișoara, Romania",
        denumireLocatieCununieCivila: "Casa Căsătoriilor",
        adresaCununieCivila: "Bulevardul Mihai Eminescu 15, Timișoara",
        oraCununieCivila: dayjs(new Date(1687600800000)),
        urlCununieCivila:
            "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJhzMRe8xdRUcR-nYGimUrOoM",
        cununieReligioasa: true,
        locatieCununieReligioasa:
            "Biserica Millenium, Piața Romanilor, Timișoara, Romania",
        denumireLocatieCununieReligioasa: "Biserica Millenium",
        adresaCununieReligioasa: "Piaţa Romanilor Nr. 2, Timișoara",
        oraCununieReligioasa: dayjs(new Date(1687608000000)),
        urlCununieReligioasa:
            "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ_1k5hmRnRUcRbjUOaoA2H1I",
        petrecere: true,
        locatiePetrecere:
            "Anne's Event Ballroom, Strada Căliman, Moșnița Nouă, Romania",
        denumireLocatiePetrecere: "Anne's Event Ballroom",
        adresaPetrecere: "Strada Căliman, Nr. 1, Moșnița Nouă",
        oraPetrecere: dayjs(new Date(1687622400000)),
        urlPetrecere:
            "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJ7V738TZeRUcRwgkK26DJnHo",
        color: {
            value: "darkorange",
            url: "/Media/Images/Modele/Nunta/darkorange",
        },
    });

    const savedSlide2Data = props.invId
        ? Cookies.get(`${props.invId}_nb1s2`)
        : Cookies.get("nb1s2");

    useEffect(() => {
        if (initialRender && !!savedSlide2Data) {
            setSlide2Data(JSON.parse(savedSlide2Data));
            setInitialRender(false);
        }
    }, [initialRender, savedSlide2Data]);

    const dispatch = useDispatch();

    const openModalSlide2 = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Editează a doua pagină",
                modalContent: (
                    <FormSlide2
                        uid={props.uid}
                        invId={props.invId}
                        invTitle={props.invTitle}
                        slideData={slide2Data}
                        setSlideData={setSlide2Data}
                        slideUpdated={props.slideUpdated}
                        editMode={props.editMode}
                    />
                ),
            })
        );
    };

    // Function to process TimePicker date
    const convertToTime = (data) => {
        const hours = new Date(data).getHours().toString();
        const minutes = new Date(data).getMinutes().toString();
        const processedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;
        return `${hours}:${processedMinutes}`;
    };

    return (
        <div id={classes.slide_2}>
            {!props.live && (
                <EditInvitatie
                    tooltipText='Editează a doua pagină'
                    openModal={openModalSlide2}
                    slide={2}
                />
            )}
            <div className={classes['locations-wrapper']}>
                {slide2Data.cununieCivila && (
                    <LocationBlock
                        icon='civil'
                        title='CUNUNIA CIVILĂ'
                        location={slide2Data.locatieCununieCivila.split(",")["0"]}
                        locationUrl={slide2Data.urlCununieCivila}
                        address={slide2Data.adresaCununieCivila}
                        hour={convertToTime(slide2Data.oraCununieCivila)}
                        color={slide2Data.color.value}
                    />
                )}
                {slide2Data.cununieReligioasa && (
                    <LocationBlock
                        icon='biserica'
                        title='CUNUNIA RELIGIOASĂ ȘI BOTEZUL'
                        location={
                            slide2Data.locatieCununieReligioasa.split(",")["0"]
                        }
                        locationUrl={slide2Data.urlCununieReligioasa}
                        address={slide2Data.adresaCununieReligioasa}
                        hour={convertToTime(slide2Data.oraCununieReligioasa)}
                        color={slide2Data.color.value}
                    />
                )}
                {slide2Data.petrecere && (
                    <LocationBlock
                        icon='petrecere'
                        title='PETRECEREA'
                        location={slide2Data.locatiePetrecere.split(",")["0"]}
                        locationUrl={slide2Data.urlPetrecere}
                        address={slide2Data.adresaPetrecere}
                        hour={convertToTime(slide2Data.oraPetrecere)}
                        color={slide2Data.color.value}
                    />
                )}
            </div>
        </div>
    );
};

export default Slide2;
