import classes from './TestChecker.module.scss';

import { useState } from "react";
import { errorHandler } from '../../../Helpers/Helpers';
import { useDispatch } from "react-redux";
import { modalActions } from '../../../store/modal-slice';
import Spinner from "../../UI/Spinner/Spinner";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextInput from "../../UI/Form/TextInput";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";

const TesterInput = () => {
    const [checking, setCheking] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [parola, setParola] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setCheking(true);
        const passwordAccepted = await fetch(
            `https://us-central1-invitatii-2c400.cloudfunctions.net/checkTesterPassword?parola=${parola.trim()}`
        )
            .then((response) => {
                setCheking(false);
                return response.json();
            })
            .catch((err) => {
                setAlertError(true);
                setCheking(false);
                setAlert(errorHandler(err));
            });
        if (passwordAccepted.correct) {
            setError('');
            setAlert({
                severity: "success",
                title: "Parolă corectă",
                description: "Puteți accesa site-ul. :)",
            });
            sessionStorage.setItem("ta", "true");
            setSuccess(true);
            dispatch(modalActions.close());
        } else {
            setError('Parola introdusă nu este corectă!');
            setSuccess(false);
        }
    }

    const onParolaChange = (text) => {
        setParola(text);
        setError("");
    }

    return (
        <div className={classes['tester-check-wrapper']}>
            {checking && <Spinner text='Verificăm parola...' />}
            {alertError && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {!checking && !success && !alertError && (
                <form onSubmit={handleSubmit}>
                    <FormControl>
                        <div className={classes.intro}>
                            <p>Acest site este încă în construcție. Reveniți mai târziu.</p>
                            <p>Dacă testezi acest site, introdu parola primită mai jos:</p>
                        </div>
                        <TextInput
                            notFullWidth
                            autoComplete='off'
                            label='Parolă'
                            value={parola}
                            changeHandler={onParolaChange}
                            error={error}
                            secret
                        />
                    </FormControl>
                    <div className={classes.actions}>
                        <Button
                            disabled={!parola.trim()}
                            type='submit'
                            variant='contained'
                        >
                            Verifică
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
}

export default TesterInput;