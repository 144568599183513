import React from "react";

const About = () => {
    return (
        <div className='page-wrapper'>
            <div className='page-content'>
                <h1>This is the about page</h1>
            </div>
        </div>
    );
};

export default About;
