const NotFound = () => {
    return (
        <div className='page-wrapper'>
            <div className='page-content'>
                <h1>404</h1>
                <h2>Această pagină nu există!</h2>
            </div>
        </div>
    );
};

export default NotFound;
