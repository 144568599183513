import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import modalSlice from "./modal-slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    modal: modalSlice.reducer,
  },
  // This is for modalActions!
  // If you pass a JSX element as 'modalContent' you receive a serializer error
  // if you don't add the below code
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
