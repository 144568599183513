import classes from './RaspunsuriRamase.module.scss';

import { useContext } from "react";
import InvitatiileMeleContext from '../../../../../context/invitatiile-mele-context';
import { useDispatch } from "react-redux";
import { modalActions } from '../../../../../store/modal-slice';
import Pachete from '../../../../Invitatii/Components/Pachete/Pachete';
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const RaspunsuriRamase = () => {
    const ctx = useContext(InvitatiileMeleContext);
    const invitesLeft = ctx.selected.invitesLeft;
    const dispatch = useDispatch();
    const handleCumparaRaspunsuri = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Alege pachetul dorit",
                modalContent:
                    <Pachete
                        iid={ctx.selected.iid}
                    />,
            })
        );
    }
    const tooltip = <p className={classes.tooltip}>Pentru a cumpăra<br/> mai multe răspunsuri<br/> <span onClick={handleCumparaRaspunsuri}>click aici</span></p>;
    return (
        <div className={classes['raspunsuri-ramase']}>
            <Tooltip TransitionComponent={Zoom} title={tooltip} arrow>
                <i className="far fa-question-circle"></i>
            </Tooltip>
            &nbsp;Răspunsuri rămase: {invitesLeft ? invitesLeft : 0}
            {invitesLeft <= 5 && invitesLeft > 0 &&
                <p>
                    <span className={classes.alert}>Atenție!</span>
                    &nbsp;Mai sunt doar {invitesLeft} răspunsuri rămase.
                    &nbsp;<span className={classes.anchor} onClick={handleCumparaRaspunsuri}>Click aici</span>
                    &nbsp;pentru a cumpăra mai multe răspunsuri.
                </p>
            }
            {(invitesLeft <= 0 || invitesLeft === undefined) &&
                <p>
                    <span className={classes.alert}>Atenție!</span>
                    &nbsp;Această invitație nu mai are răspunsuri disponibile.
                    &nbsp;<span className={classes.anchor} onClick={handleCumparaRaspunsuri}>Click aici</span>
                    &nbsp;pentru a cumpăra mai multe răspunsuri.
                </p>
            }
        </div>
    );
}

export default RaspunsuriRamase;