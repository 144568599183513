import classes from '../SettingsMenu.module.scss';

import FormEditeazaTitlu from './FormEditeazaTitlu/FormEditeazaTitlu';
import { useContext } from "react";
import InvitatiileMeleContext from '../../../../../../context/invitatiile-mele-context';
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../../store/modal-slice";
import MenuItem from '@mui/material/MenuItem';

const EditeazaTitlu = () => {
    const ctx = useContext(InvitatiileMeleContext);
    const dispatch = useDispatch();

    const openEditTitle = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Editează titlul invitației",
                modalContent: <FormEditeazaTitlu
                    uid={ctx.uid}
                    selected={ctx.selected}
                    data={ctx.data}
                    updateTitleState={(title) => ctx.updateTitleState(title)}
                />
            })
        );
    };
    return (
        <MenuItem
            className={classes['menu-item']}
            onClick={openEditTitle}
        >
            <i className={`fas fa-edit ${classes.icon}`}></i> Editează titlul invitației
        </MenuItem>
    );
}

export default EditeazaTitlu;
