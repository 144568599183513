import classes from '../Admin.module.scss'
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorHandler } from "../../../Helpers/Helpers";
import { giveAdminRights } from "../../../firebase-admin";
import TextInput from "../../UI/Form/TextInput";
import Radios from "../../UI/Form/Radios";
import Spinner from "../../UI/Spinner/Spinner";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";
import { Button } from "@mui/material";

const CreateAdmin = () => {
    const [error, setError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alert, setAlert] = useState({});
    const onEmailChange = (email) => {
        formik.setFieldValue("email", email, !!formik.touched.email);
      };
    const onAdminChange = (admin) => {
        formik.setFieldValue("admin", admin, !!formik.touched.admin);
      };

    const formik = useFormik({
        initialValues: {
          email: "",
          admin: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Introduceți o adresă de email validă")
                .required("Introduceți o adresă de email"),
            admin: Yup.string().required("Acest câmp este obligatoriu!")
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: (values) => {
          setIsSaving(true);
          giveAdminRights(values.email, values.admin === "da" ? true : false)
            .then((res) => {
                if (res?.errorInfo?.code) {
                    setAlert(errorHandler(res.errorInfo));
                    setSuccess(false);
                    setError(true);
                } else {
                    setAlert({
                        severity: "success",
                        title: "Cont updatat",
                        description: `${values.email} ${values.admin === 'da' ? 'este acum' : 'nu mai este'} administrator!`,
                    });
                    setSuccess(true);
                }
            })
            .catch((error) => {
                setAlert(errorHandler(error));
                setSuccess(false);
                setError(true);
            })
            .finally(() => {
                setIsSaving(false);
            });
        },
      });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text="Se salvează..." />}
            {error && (
                <AlertMessage
                severity={alert.severity}
                title={alert.title}
                description={alert.description}
                refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                severity={alert.severity}
                title={alert.title}
                description={alert.description}
                refresh={alert.refresh}
                />
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                        <p>Adaugă emailul utilizatorului căruia dorești să îi updatezi drepturile de admin:</p>
                        <TextInput
                            autoComplete='off'
                            label='Email'
                            value={formik.values.email}
                            changeHandler={onEmailChange}
                            error={formik.errors.email}
                        />
                        <Radios
                            row
                            label='Dai drepturi de admin?'
                            options={["Da", "Nu"]}
                            value={formik.values.admin}
                            error={formik.errors.admin}
                            changeHandler={onAdminChange}
                        />
                    <div className={classes["line-wrapper"]}>
                        <Button
                            id={classes.submitButton}
                            type="submit"
                            variant='contained'
                        >
                            Salvează
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
}

export default CreateAdmin;