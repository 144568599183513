import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchInvitationData, fetchInvitesLeft } from "../../../firebase-invitatii";

import { errorHandler } from "../../../Helpers/Helpers";
import Cookies from "js-cookie";
import Spinner from "../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../Components/UI/AlertMessage/AlertMessage";
import { useSelector } from "react-redux";

import InvitatieNunta1 from "../Nunta/InvitatieNunta1/InvitatieNunta1";
import InvitatieBotez1 from "../Botez/InvitatieBotez1/InvitatieBotez1";
import InvitatieNuntaBotez1 from "../NuntaBotez/InvitatieNuntaBotez1/InvitatieNuntaBotez1";
import InvitatieAniversare1 from "../Aniversare/InvitatieAniversare1/InvitatieAniversare1";

const Links = (props) => {
    const { id } = useParams();
    const storedUrlData = Cookies.get(id) ? JSON.parse(Cookies.get(id)) : null,
        storedInvData = Cookies.get(`${id}_data`) ? JSON.parse(Cookies.get(`${id}_data`)) : null,
        isLoggedIn = useSelector((state) => state.auth.uid);
    const [urlNotFound, setUrlNotFound] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [urlData, setUrlData] = useState(storedUrlData || null);
    const [invData, setInvData] = useState(storedInvData ?? null);
    const [invitesLeft, setInvitesLeft] = useState(null);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});

    useEffect(() => {
        const fetchUrlData = async () => {
            if (!urlData) {
                // Fetching URL data
                const data = await fetch(
                    `https://us-central1-invitatii-2c400.cloudfunctions.net/retrieveUrlData?url=${id}`
                )
                    .then((response) => {
                        return response.json();
                    })
                    .catch((err) => {
                        setAlertError(true);
                        setIsLoading(false);
                        setAlert(errorHandler(err));
                    });
                if (data.error) {
                    setUrlNotFound(true);
                    setIsLoading(false);
                } else {
                    Cookies.set(id, JSON.stringify(data), { expires: 1 / 24 });
                    setUrlData(data);
                    // Fetching invitation data
                    if (!invData) {
                        const invitationData = await fetchInvitationData(
                            data.invitationId,
                            data.invitationType,
                            data.uid
                        )
                            .then((response) => {
                                return response;
                            })
                            .catch((err) => {
                                setAlertError(true);
                                setIsLoading(false);
                                setAlert(errorHandler(err));
                            });
                        Cookies.set(`${id}_data`, JSON.stringify(await invitationData), { expires: 1 / 24 });
                        setInvData(await invitationData);
                        setIsLoading(false);
                    }
                }
            } else {
                setIsLoading(false);
            }
            if (urlData) {
                // Fetch invites left
                const invites_left = await fetchInvitesLeft(
                    urlData.uid,
                    urlData.invitationId)
                        .then((response) => {
                            return response;
                        })
                        .catch((err) => {
                            setAlertError(true);
                            setIsLoading(false);
                            setAlert(errorHandler(err));
                        });
                setInvitesLeft(invites_left);
            }
        };
        fetchUrlData();
    }, [urlData, id, invData]);

if (invitesLeft != null && invitesLeft <= 0) {
    Cookies.remove(id);
    Cookies.remove(`${id}_data`);
};

    return (
        <>
            {isLoading && <Spinner text='Se încarcă pagina...' />}
            {alertError && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {!isLoading && !alertError && (
                <>
                    {urlNotFound && (
                        <div className='page-wrapper'>
                            <div className='page-content'>
                                <AlertMessage
                                    severity='error'
                                    title='Acest link nu există'
                                    description='Se pare ca aceast link nu există. Asigurați-vă că aveți linkul corect.'
                                />
                            </div>
                        </div>
                    )}
                    {(invitesLeft != null && invitesLeft <= 0) && (
                        <div className='page-wrapper'>
                            <div className='page-content'>
                                <AlertMessage
                                    severity='error'
                                    title='Invitație indisponibilă'
                                    description='Această invitație și-a atins numărul maxim de răspunsuri alocate. Cel care ți-a trimis acest link va trebui să mai cumpere răspunsuri pentru a o reactiva.'
                                />
                            </div>
                        </div>
                    )}
                    {!urlNotFound && invitesLeft > 0 && (
                        <>
                            {urlData?.invitationType === "n1" && invData && (
                                <InvitatieNunta1
                                    invId={id}
                                    data={{
                                        n1s1: invData[
                                            `${urlData?.invitationType}s1`
                                        ],
                                        n1s2: invData[
                                            `${urlData?.invitationType}s2`
                                        ],
                                        n1s3: invData[
                                            `${urlData?.invitationType}s3`
                                        ],
                                    }}
                                    isLoggedIn={isLoggedIn}
                                    live={true}
                                    urlData={urlData}
                                />
                            )}
                            {urlData?.invitationType === "b1" && invData && (
                                <InvitatieBotez1
                                    invId={id}
                                    data={{
                                        b1s1: invData[
                                            `${urlData?.invitationType}s1`
                                        ],
                                        b1s2: invData[
                                            `${urlData?.invitationType}s2`
                                        ],
                                        b1s3: invData[
                                            `${urlData?.invitationType}s3`
                                        ],
                                    }}
                                    isLoggedIn={isLoggedIn}
                                    live={true}
                                    urlData={urlData}
                                />
                            )}
                            {urlData?.invitationType === "nb1" && invData && (
                                <InvitatieNuntaBotez1
                                    invId={id}
                                    data={{
                                        nb1s1: invData[
                                            `${urlData?.invitationType}s1`
                                        ],
                                        nb1s2: invData[
                                            `${urlData?.invitationType}s2`
                                        ],
                                        nb1s3: invData[
                                            `${urlData?.invitationType}s3`
                                        ],
                                    }}
                                    isLoggedIn={isLoggedIn}
                                    live={true}
                                    urlData={urlData}
                                />
                            )}
                            {urlData?.invitationType === "a1" && invData && (
                                <InvitatieAniversare1
                                    invId={id}
                                    data={{
                                        a1s1: invData[
                                            `${urlData?.invitationType}s1`
                                        ],
                                        a1s2: invData[
                                            `${urlData?.invitationType}s2`
                                        ],
                                        a1s3: invData[
                                            `${urlData?.invitationType}s3`
                                        ],
                                    }}
                                    isLoggedIn={isLoggedIn}
                                    live={true}
                                    urlData={urlData}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default Links;
