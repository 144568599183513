import classes from './FormLogo.module.scss';

import Logo from '../../../../by-logo.svg';

const FormLogo = () => {
    return (
        <div className={classes['logo-wrapper']}>
            <a href="/">
                <span>Invitație realizată cu ajutorul</span>
                <img src={Logo} alt='Happy Events' />
            </a>
        </div>
    );
}

export default FormLogo;