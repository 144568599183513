import classes from "../../../Slides1.module.scss";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import dayjs from "dayjs";
import { dateToRo } from "../../../../../Helpers/Helpers";
import { v4 as uuidv4 } from "uuid";
import EditInvitatie from "../../../Components/Edit/Edit";
import FormSlide1 from "./FormSlide1";
import Countdown from "../../../Components/Countdown/Countdown";
import Scroll from "../../../Components/Scroll/Scroll";
import Cookies from "js-cookie";

const lastSaturday = (demoNunta) => {
    const t =
        new Date(demoNunta).getDate() + (6 - new Date(demoNunta).getDay()) - 7;
    const lastSaturday = new Date(demoNunta);
    lastSaturday.setDate(t);
    return lastSaturday;
};
const saturdayIn3Months = lastSaturday(
    new Date().setMonth(new Date().getMonth() + 3)
);
const saturdayIn2Months = lastSaturday(
    new Date().setMonth(new Date().getMonth() + 2)
);

const Slide1 = (props) => {
    const { invId, uid, invTitle, setEventDate, slideUpdated, editMode, live } = props;
    const [initialRender, setInitialRender] = useState(true);
    const [slide1Data, setSlide1Data] = useState({
        iid: uuidv4(),
        image: {
            value: "a1s1_1",
            url: "/Media/Images/Modele/Aniversare/a1s1_1",
        },
        titlu: "Vine ziua mea!",
        text: "După cum bine știi, urmează ziua mea de naștere, așa că te anunț din timp să nu îți faci alt program și să fii disponibil.",
        numeSarbatorit: "Tudor",
        numarSarbatorit: "0722333444",
        tipEveniment: "petrecere",
        dataEveniment: dayjs(saturdayIn3Months),
        dataConfirmare: dayjs(saturdayIn2Months),
    });

    const savedSlide1Data = invId
        ? Cookies.get(`${props.invId}_a1s1`)
        : Cookies.get("a1s1");

    const savedSlide2Data = invId
        ? Cookies.get(`${props.invId}_a1s1`)
        : Cookies.get("a1s1");

    useEffect(() => {
        if (initialRender && !!savedSlide1Data) {
            setSlide1Data(JSON.parse(savedSlide1Data));
            setInitialRender(false);
        }
    }, [initialRender, savedSlide1Data]);

    const dispatch = useDispatch();

    const openModalSlide1 = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Editează prima pagină",
                modalContent: (
                    <FormSlide1
                        uid={uid}
                        invId={invId}
                        invTitle={invTitle}
                        slideData={slide1Data}
                        setSlideData={setSlide1Data}
                        setEventDate={setEventDate}
                        slideUpdated={slideUpdated}
                        editMode={editMode}
                    />
                ),
            })
        );
    };

    return (
        <div id={classes.slide_1}>
            {!live && (
                <EditInvitatie
                    tooltipText='Editează prima pagină'
                    openModal={openModalSlide1}
                    slide={1}
                />
            )}
            <div
                className={classes.image}
                style={{
                    backgroundImage: `url(${slide1Data.image.url}.jpg)`,
                }}
            >
                <div className={classes['titlu-wrapper-small']}>
                    <h1 className={classes['titlu-small']}>{slide1Data.titlu}</h1>
                </div>
                <div className={classes['scroll-wrapper-small']}>
                    <Scroll scrollTo={classes.slide_1_2} />
                </div>
            </div>
            <div id={classes.slide_1_2} className={classes.text}>
                <div className={classes["text-content"]}>
                    <div>
                        <h2 className={classes['titlu-desktop']}>{slide1Data.titlu}</h2>
                    </div>
                    <p>{slide1Data.text}</p>
                    <h4>
                        {slide1Data.numeSarbatorit} te invită la {slide1Data.tipEveniment}
                    </h4>
                    <Countdown
                        date={new Date(
                            slide1Data.dataEveniment
                        ).toLocaleDateString("en-US")}
                        color='black'
                        type='aniversare'
                        eventHour={live ? new Date(savedSlide2Data.oraAniversare) : new Date()}
                    />
                    <div className={classes.bottom}>
                        <p>
                            * Te rog să confirmi prezenţa până la data de{" "}
                            <strong>
                                {dateToRo(slide1Data.dataConfirmare)}
                            </strong>
                            .
                            <br />
                            ** Pentru orice nelămuriri mă poți contacta la
                            telefon{" "}
                            <a href={`tel:${slide1Data.numarSarbatorit}`}>
                                {slide1Data.numarSarbatorit}
                            </a>{" "}
                            ({slide1Data.numeSarbatorit}).
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slide1;
