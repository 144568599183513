import classes from "../../../Form1.module.scss";
import styles from "../../../FormSlide2.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import CheckBox from "../../../../../Components/UI/Form/CheckBox";
import TextInput from "../../../../../Components/UI/Form/TextInput";
import TimePickerInput from "../../../../../Components/UI/Form/TimePickerInput";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import LocationAutocomplete from "../../../Components/LocationAutocomplete/LocationAutocomplete";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";

const FormSlide2 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [showCununieCivila, setShowCununieCivila] = useState(
        props.slideData.cununieCivila
    );
    const [showCununieReligioasa, setShowCununieReligioasa] = useState(
        props.slideData.cununieReligioasa
    );
    const [showPetrecere, setShowPetrecere] = useState(
        props.slideData.petrecere
    );

    const dispatch = useDispatch();

    const locallySaveSlide2 = (labelName, dataSlide2, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide2), { expires: 1 / 24 * hoursToExpire });
    };

    // CUNUNIE CIVILĂ
    const onCununieCivilaChange = (cununie_civila) => {
        formik.setFieldValue(
            "cununieCivila",
            cununie_civila,
            !!formik.touched.cununieCivila
        );
        setShowCununieCivila(cununie_civila);
    };

    const onDenumireLocatieCununieCivilaChange = (
        denumire_locatie_cununie_civila
    ) => {
        formik.setFieldValue(
            "denumireLocatieCununieCivila",
            denumire_locatie_cununie_civila,
            !!formik.touched.denumireLocatieCununieCivila
        );
    };

    const onAdresaCununieCivilaChange = (adresa_cununie_civila) => {
        formik.setFieldValue(
            "adresaCununieCivila",
            adresa_cununie_civila,
            !!formik.touched.adresaCununieCivila
        );
    };

    const onOraCununieCivilaChange = (ora_cununie_civila) => {
        formik.setFieldValue(
            "oraCununieCivila",
            ora_cununie_civila,
            !!formik.touched.oraCununieCivila
        );
    };

    const onLocatieCununieCivilaChange = (data) => {
        formik.setFieldValue(
            "locatieCununieCivila",
            data.description,
            !!formik.touched.locatieCununieCivila
        );
        if (data.description) {
            formik.setFieldValue(
                "denumireLocatieCununieCivila",
                data.description?.split(",")["0"],
                !!formik.touched.locatieCununieCivila
            );
            formik.setFieldValue(
                "adresaCununieCivila",
                data.description?.replace(
                    `${data.description?.split(",")["0"]},`,
                    ""
                ),
                !!formik.touched.locatieCununieCivila
            );
            formik.setFieldValue(
                "urlCununieCivila",
                `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data.place_id}`,
                !!formik.touched.locatieCununieCivila
            );
        }
    };

    // CUNUNIE RELIGIOASĂ
    const onCununieReligioasaChange = (cununie_religioasa) => {
        formik.setFieldValue(
            "cununieReligioasa",
            cununie_religioasa,
            !!formik.touched.cununieReligioasa
        );
        setShowCununieReligioasa(cununie_religioasa);
    };

    const onDenumireLocatieCununieReligioasaChange = (
        denumire_locatie_cununie_religioasa
    ) => {
        formik.setFieldValue(
            "denumireLocatieCununieReligioasa",
            denumire_locatie_cununie_religioasa,
            !!formik.touched.denumireLocatieCununieReligioasa
        );
    };

    const onAdresaCununieReligioasaChange = (adresa_cununie_religioasa) => {
        formik.setFieldValue(
            "adresaCununieReligioasa",
            adresa_cununie_religioasa,
            !!formik.touched.adresaCununieReligioasa
        );
    };

    const onOraCununieReligioasaChange = (ora_cununie_religioasa) => {
        formik.setFieldValue(
            "oraCununieReligioasa",
            ora_cununie_religioasa,
            !!formik.touched.oraCununieReligioasa
        );
    };

    const onLocatieCununieReligioasaChange = (data) => {
        formik.setFieldValue(
            "locatieCununieReligioasa",
            data.description,
            !!formik.touched.locatieCununieReligioasa
        );
        formik.setFieldValue(
            "denumireLocatieCununieReligioasa",
            data.description?.split(",")["0"],
            !!formik.touched.locatieCununieReligioasa
        );
        if (data.description) {
            formik.setFieldValue(
                "adresaCununieReligioasa",
                data.description?.replace(
                    `${data.description?.split(",")["0"]},`,
                    ""
                ),
                !!formik.touched.locatieCununieReligioasa
            );
            formik.setFieldValue(
                "urlCununieReligioasa",
                `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data.place_id}`,
                !!formik.touched.locatieCununieReligioasa
            );
        }
    };

    // PETRECERE
    const onPetrecereChange = (petrecere) => {
        formik.setFieldValue(
            "petrecere",
            petrecere,
            !!formik.touched.petrecere
        );
        setShowPetrecere(petrecere);
    };

    const onDenumireLocatiePetrecereChange = (denumire_locatie_petrecere) => {
        formik.setFieldValue(
            "denumireLocatiePetrecer",
            denumire_locatie_petrecere,
            !!formik.touched.denumireLocatiePetrecere
        );
    };

    const onAdresaPetrecereChange = (adresa_petrecere) => {
        formik.setFieldValue(
            "adresaPetrecere",
            adresa_petrecere,
            !!formik.touched.adresaPetrecere
        );
    };

    const onOraPetrecereChange = (ora_petrecere) => {
        formik.setFieldValue(
            "oraPetrecere",
            ora_petrecere,
            !!formik.touched.oraPetrecere
        );
    };

    const onLocatiePetrecereChange = (data) => {
        formik.setFieldValue(
            "locatiePetrecere",
            data.description,
            !!formik.touched.locatiePetrecere
        );
        formik.setFieldValue(
            "denumireLocatiePetrecere",
            data.description?.split(",")["0"],
            !!formik.touched.locatiePetrecere
        );
        if (data.description) {
            formik.setFieldValue(
                "adresaPetrecere",
                data.description?.replace(
                    `${data.description?.split(",")["0"]},`,
                    ""
                ),
                !!formik.touched.locatiePetrecere
            );
            formik.setFieldValue(
                "urlPetrecere",
                `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data.place_id}`,
                !!formik.touched.locatiePetrecere
            );
        }
    };

    const onColorChange = (color) => {
        formik.setFieldValue("color", color, !!formik.touched.color);
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            cununieCivila: props.slideData.cununieCivila,
            locatieCununieCivila: props.slideData.locatieCununieCivila,
            denumireLocatieCununieCivila:
                props.slideData.denumireLocatieCununieCivila,
            adresaCununieCivila: props.slideData.adresaCununieCivila,
            oraCununieCivila: dayjs(props.slideData.oraCununieCivila),
            urlCununieCivila: props.slideData.urlCununieCivila,
            cununieReligioasa: props.slideData.cununieReligioasa,
            locatieCununieReligioasa: props.slideData.locatieCununieReligioasa,
            denumireLocatieCununieReligioasa:
                props.slideData.denumireLocatieCununieReligioasa,
            adresaCununieReligioasa: props.slideData.adresaCununieReligioasa,
            oraCununieReligioasa: dayjs(props.slideData.oraCununieReligioasa),
            urlCununieReligioasa: props.slideData.urlCununieReligioasa,
            petrecere: props.slideData.petrecere,
            locatiePetrecere: props.slideData.locatiePetrecere,
            denumireLocatiePetrecere: props.slideData.denumireLocatiePetrecere,
            adresaPetrecere: props.slideData.adresaPetrecere,
            oraPetrecere: dayjs(props.slideData.oraPetrecere),
            urlPetrecere: props.slideData.urlPetrecere,
            color: props.slideData.color,
        },
        validationSchema: Yup.object()
            .shape({
                cununieCivila: Yup.bool(),
                locatieCununieCivila: Yup.string().required(
                    "Locația unde are loc cununia civilă este oblicatorie!"
                ),
                denumireLocatieCununieCivila: Yup.string().required(
                    "Denumirea locației unde are loc cununia civilă este oblicatorie!"
                ),
                adresaCununieCivila: Yup.string().required(
                    "Adresa locației unde are loc cununia civilă este oblicatorie!"
                ),
                oraCununieCivila: Yup.string().required(
                    "Ora la care are loc cununia civilă este oblicatorie!"
                ),
                cununieReligioasa: Yup.bool(),
                locatieCununieReligioasa: Yup.string().required(
                    "Locația unde are loc cununia religioasă este oblicatorie!"
                ),
                denumireLocatieCununieReligioasa: Yup.string().required(
                    "Denumirea locației unde are loc cununia religioasă este oblicatorie!"
                ),
                adresaCununieReligioasa: Yup.string().required(
                    "Adresa locației unde are loc cununia religioasă este oblicatorie!"
                ),
                oraCununieReligioasa: Yup.string().required(
                    "Ora la care are loc cununia religioasă este oblicatorie!"
                ),
                petrecere: Yup.bool(),
                locatiePetrecere: Yup.string().required(
                    "Locația unde are loc petrecerea este oblicatorie!"
                ),
                denumireLocatiePetrecere: Yup.string().required(
                    "Denumirea locației unde are loc petrecerea este oblicatorie!"
                ),
                adresaPetrecere: Yup.string().required(
                    "Adresa locației unde are loc petrecerea este oblicatorie!"
                ),
                oraPetrecere: Yup.string().required(
                    "Ora la care are loc petrecerea este oblicatorie!"
                ),
            })
            .test("checkBoxesTest", null, (obj) => {
                if (
                    obj.cununieCivila ||
                    obj.cununieReligioasa ||
                    obj.petrecere
                ) {
                    return true;
                }
                return new Yup.ValidationError(
                    "Cel puțin una dintre secțiuni trebuie să fie activată!",
                    null,
                    "minOneLocation"
                );
            }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide2Data = {
                cununieCivila: values.cununieCivila,
                locatieCununieCivila: values.locatieCununieCivila,
                denumireLocatieCununieCivila:
                    values.denumireLocatieCununieCivila,
                adresaCununieCivila: values.adresaCununieCivila,
                oraCununieCivila: values.oraCununieCivila,
                urlCununieCivila: values.urlCununieCivila,
                cununieReligioasa: values.cununieReligioasa,
                locatieCununieReligioasa: values.locatieCununieReligioasa,
                denumireLocatieCununieReligioasa:
                    values.denumireLocatieCununieReligioasa,
                adresaCununieReligioasa: values.adresaCununieReligioasa,
                oraCununieReligioasa: values.oraCununieReligioasa,
                urlCununieReligioasa: values.urlCununieReligioasa,
                petrecere: values.petrecere,
                locatiePetrecere: values.locatiePetrecere,
                denumireLocatiePetrecere: values.denumireLocatiePetrecere,
                adresaPetrecere: values.adresaPetrecere,
                oraPetrecere: values.oraPetrecere,
                urlPetrecere: values.urlPetrecere,
                color: values.color,
            };
            props.setSlideData(slide2Data);
            if (props.editMode) {
                const invData = {
                    n1s1: JSON.parse(Cookies.get(`${props.invId}_n1s1`)),
                    n1s2: JSON.parse(Cookies.get(`${props.invId}_n1s2`)),
                    n1s3: JSON.parse(Cookies.get(`${props.invId}_n1s3`)),
                };
                locallySaveSlide2(`${invData.n1s1.iid}_n1s2`, slide2Data, 1);
                invData.n1s2 = JSON.parse(Cookies.get(`${invData.n1s1.iid}_n1s2`));
                await saveOrUpdateInvitationData(
                    props.uid,
                    'n1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide2('n1s2', slide2Data, 24);
                setIsSaving(false);
                props.slideUpdated(true);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: `Modificările au fost salvate cu succes!`,
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={`${classes["form-wrapper"]} ${styles["form-slide-2"]}`}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <p>
                        În funcție de evenimentul dumneavoastră activați sau
                        dezactivați secțiunile de care aveți nevoie.
                    </p>
                    {/* CUNUNIE CIVILĂ */}
                    <Box className={styles.location}>
                        <CheckBox
                            label='Cununia civilă'
                            value={formik.values.cununieCivila}
                            changeHandler={onCununieCivilaChange}
                        />
                        <div className={styles["location-inputs"]}>
                            <LocationAutocomplete
                                label='Caută locație'
                                noLocation='Nicio locație'
                                value={formik.values.locatieCununieCivila}
                                changeHandler={onLocatieCununieCivilaChange}
                                error={formik.errors.locatieCununieCivila}
                                disabled={!showCununieCivila}
                            />
                            <TextInput
                                label='Denumire locație'
                                value={
                                    formik.values.denumireLocatieCununieCivila
                                }
                                placeholder='Casa Căsătoriilor'
                                changeHandler={
                                    onDenumireLocatieCununieCivilaChange
                                }
                                error={
                                    formik.errors.denumireLocatieCununieCivila
                                }
                                disabled={!showCununieCivila}
                            />
                            <TextInput
                                label='Adresa'
                                value={formik.values.adresaCununieCivila}
                                placeholder='Bulevardul Mihai Eminescu 15, Timișoara'
                                changeHandler={onAdresaCununieCivilaChange}
                                error={formik.errors.adresaCununieCivila}
                                disabled={!showCununieCivila}
                            />
                            <TimePickerInput
                                label='Ora'
                                value={dayjs(formik.values.oraCununieCivila)}
                                changeHandler={onOraCununieCivilaChange}
                                error={formik.errors.oraCununieCivila}
                                disabled={!showCununieCivila}
                            />
                        </div>
                    </Box>
                    <hr className={styles.hr} />
                    {/* CUNUNIE RELIGIOASĂ */}
                    <Box className={styles.location}>
                        <CheckBox
                            label='Cununia religioasă'
                            value={formik.values.cununieReligioasa}
                            changeHandler={onCununieReligioasaChange}
                        />
                        <div className={styles["location-inputs"]}>
                            <LocationAutocomplete
                                label='Caută locație'
                                noLocation='Nicio locație'
                                value={formik.values.locatieCununieReligioasa}
                                changeHandler={onLocatieCununieReligioasaChange}
                                error={formik.errors.locatieCununieReligioasa}
                                disabled={!showCununieReligioasa}
                            />
                            <TextInput
                                label='Denumire locație'
                                value={
                                    formik.values
                                        .denumireLocatieCununieReligioasa
                                }
                                placeholder='Biserica Millenium'
                                changeHandler={
                                    onDenumireLocatieCununieReligioasaChange
                                }
                                error={
                                    formik.errors
                                        .denumireLocatieCununieReligioasa
                                }
                                disabled={!showCununieReligioasa}
                            />
                            <TextInput
                                label='Adresa'
                                value={formik.values.adresaCununieReligioasa}
                                placeholder='Piaţa Romanilor Nr. 2, Timișoara'
                                changeHandler={onAdresaCununieReligioasaChange}
                                error={formik.errors.adresaCununieReligioasa}
                                disabled={!showCununieReligioasa}
                            />
                            <TimePickerInput
                                label='Ora'
                                value={dayjs(formik.values.oraCununieReligioasa)}
                                changeHandler={onOraCununieReligioasaChange}
                                error={formik.errors.oraCununieReligioasa}
                                disabled={!showCununieReligioasa}
                            />
                        </div>
                    </Box>
                    <hr className={styles.hr} />
                    {/* PETRECEREA */}
                    <Box className={styles.location}>
                        <CheckBox
                            label='Petrecerea'
                            value={formik.values.petrecere}
                            changeHandler={onPetrecereChange}
                            error={formik.errors.petrecere}
                        />
                        <div className={styles["location-inputs"]}>
                            <LocationAutocomplete
                                label='Caută locație'
                                noLocation='Nicio locație'
                                value={formik.values.locatiePetrecere}
                                changeHandler={onLocatiePetrecereChange}
                                error={formik.errors.locatiePetrecere}
                                disabled={!showPetrecere}
                            />
                            <TextInput
                                label='Denumire locație'
                                value={formik.values.denumireLocatiePetrecere}
                                placeholder="Anne's Event Ballroom"
                                changeHandler={onDenumireLocatiePetrecereChange}
                                error={formik.errors.denumireLocatiePetrecere}
                                disabled={!showPetrecere}
                            />
                            <TextInput
                                label='Adresa'
                                value={formik.values.adresaPetrecere}
                                placeholder='Strada Căliman, Nr. 1, Moșnița Nouă'
                                changeHandler={onAdresaPetrecereChange}
                                error={formik.errors.adresaPetrecere}
                                disabled={!showPetrecere}
                            />
                            <TimePickerInput
                                label='Ora'
                                value={dayjs(formik.values.oraPetrecere)}
                                changeHandler={onOraPetrecereChange}
                                error={formik.errors.oraPetrecere}
                                disabled={!showPetrecere}
                            />
                        </div>
                    </Box>
                    <hr className={styles.hr} />
                    <ImageRadios
                        default={props.slideData.color}
                        text='Culoarea chenarelor:'
                        labels={[
                            "darkorange",
                            "deeppink",
                            "dodgerblue",
                            "gold",
                            "limegreen",
                            "maroon",
                            "mediumturquoise",
                            "burlywood",
                            "red",
                            "violet",
                        ]}
                        path='Nunta'
                        changeHandler={onColorChange}
                    />
                    {formik.errors.minOneLocation && (
                        <p className='error-alert'>
                            {formik.errors.minOneLocation}
                        </p>
                    )}
                    <Button type='submit' variant='contained'>
                        Actualizare
                    </Button>
                    {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
                </form>
            )}
        </div>
    );
};

export default FormSlide2;
