import classes from "./Classes.module.scss";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Textarea from "@mui/joy/Textarea";
import FormHelperText from "@mui/material/FormHelperText";

const TextareaInput = (props) => {
    const inputChangeHandler = (event) => {
        props.changeHandler(event.target.value);
    };

    return (
        <FormControl
            fullWidth={!props.notFullWidth}
            component='fieldset'
            className={classes.formFieldWrapper}
        >
            {props.label && <FormLabel>{props.label}</FormLabel>}
            <FormHelperText style={{ color: "#d32f2f" }}>
                {props.error}
            </FormHelperText>
            <Textarea
                className={classes.textarea}
                aria-label='textarea'
                minRows={3}
                maxRows={4}
                placeholder={props.placeholder}
                error={!!props.error}
                id={props.label.replaceAll(" ", "")}
                onChange={inputChangeHandler}
                value={props.value}
                disabled={!!props.disabled}
            />
        </FormControl>
    );
};

export default TextareaInput;
