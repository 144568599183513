import classes from './Tabele.module.scss';

import { menus } from '../../../../../Helpers/Helpers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const TabelNunta = (props) => {
    return (
        <div className={classes['table-wrapper']}>
            <TableContainer className={classes.table} component={Paper} sx={{ maxHeight: 'inherit' }}>
                <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Nume și prenume</TableCell>
                            <TableCell align="center">Participă</TableCell>
                            <TableCell align="center">Număr adulți</TableCell>
                            <TableCell align="center">Meniuri normale adulți</TableCell>
                            <TableCell align="center">Meniuri vegetariene adulți</TableCell>
                            <TableCell align="center">Număr copii</TableCell>
                            <TableCell align="center">Meniuri normale copii</TableCell>
                            <TableCell align="center">Meniuri vegetariene copii</TableCell>
                            <TableCell align="center">Copii fără meniu</TableCell>
                            <TableCell align="center">Cazare</TableCell>
                            <TableCell align="center">Observații</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.answers.map((row) => (
                            <TableRow key={row.nume_prenume}>
                                <TableCell>
                                    {row.nume_prenume}
                                </TableCell>
                                <TableCell
                                    align="center">{row.participa}
                                </TableCell>
                                <TableCell
                                    align="center">{row.adulti || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_adulti)['1'] || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_adulti)['2'] || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{row.copii || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_copii)['1'] || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_copii)['2'] || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_copii)['3'] || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{row.cazare || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{row.observatii || '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TabelNunta;