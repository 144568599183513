import { useState } from 'react';
import ByInvitationId from './ByInvitationId/ByInvitationId';
import Result from "./Result/Result";

const Finder = () => {
    const [invData, setInvData] = useState(null);
    const handleInvData = (data) => {
        setInvData(data);
    }
    return (
        <>
            <ByInvitationId handleData={handleInvData} />
            <Result handleData={handleInvData} invData={invData} />
        </>
    );
}

export default Finder;