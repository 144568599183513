import classes from '../../Admin.module.scss';

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { findInvitationData } from "../../../../firebase-admin";
import TextInput from "../../../UI/Form/TextInput";
import Spinner from "../../../UI/Spinner/Spinner";
import AlertMessage from "../../../UI/AlertMessage/AlertMessage";
import { errorHandler } from "../../../../Helpers/Helpers";
import { Button } from "@mui/material";

const ByInvitationId = (props) => {
    const [error, setError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alert, setAlert] = useState({});
    const { handleData } = props;
    const onInvIdChange = (invId) => {
        formik.setFieldValue("invId", invId, !!formik.touched.invId);
      };
      const formik = useFormik({
        initialValues: {
            invId: ""
        },
        validationSchema: Yup.object({
            invId: Yup.string()
                .required("Introduceți un ID de invitație valid!"),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: (values) => {
            setIsSaving(true);
            findInvitationData(values.invId)
                .then((res) => {
                    if (res?.errorTitle) {
                        setAlert({
                            severity: "error",
                            title: res.errorTitle,
                            description: res.errorDescription
                        });
                        setSuccess(false);
                        setError(true);
                        handleData(null);
                        setTimeout(() => {
                            formik.values.invId = '';
                            setError(false);
                            setAlert({});
                        }, 2000);
                    } else {
                        handleData(res);
                        setAlert({
                            severity: "success",
                            title: "Am găsit!",
                            description: "Rezultatul îl găsești mai jos.",
                        });
                        setSuccess(true);
                        setTimeout(() => {
                            formik.values.invId = '';
                            setSuccess(false);
                            setAlert({});
                        }, 2000);
                    }
                })
                .catch((error) => {
                    setAlert(errorHandler(error));
                    setSuccess(false);
                    setError(true);
                })
                .finally(() => {
                    setIsSaving(false);
                });
        },
      });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text="Se caută..." />}
            {error && (
                <AlertMessage
                severity={alert.severity}
                title={alert.title}
                description={alert.description}
                refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                severity={alert.severity}
                title={alert.title}
                description={alert.description}
                refresh={alert.refresh}
                />
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                        <div className={classes["line-wrapper-center"]}>
                            <p>După ID-ul invitației:</p>
                            <TextInput
                                notFullWidth
                                autoComplete='off'
                                label='ID invitație'
                                value={formik.values.invId}
                                changeHandler={onInvIdChange}
                                error={formik.errors.invId}
                            />
                            <Button
                                className={classes['inline-button']}
                                type="submit"
                                variant='contained'
                            >
                                Caută
                            </Button>
                    </div>
                </form>
            )}
        </div>
    );
}

export default ByInvitationId;