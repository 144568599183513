import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

const CheckBox = (props) => {
  const checkboxChangeHandler = (event) => {
    props.changeHandler(event.target.checked);
  };
  return (
    <FormControl error={!!props.error}>
      <FormControlLabel
        control={<Checkbox checked={props.value} />}
        label={props.label}
        onChange={checkboxChangeHandler}
      />
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};

export default CheckBox;
