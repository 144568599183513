import classes from './FormEditeazaTitlu.module.scss';

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorHandler } from '../../../../../../../Helpers/Helpers';
import { Button } from "@mui/material";
import { modalActions } from "../../../../../../../store/modal-slice";
import AlertMessage from '../../../../../../../Components/UI/AlertMessage/AlertMessage';
import Spinner from '../../../../../../../Components/UI/Spinner/Spinner';
import TextInput from "../../../../../../../Components/UI/Form/TextInput";
import { updateInvitationTitle } from '../../../../../../../firebase-invitatii';

const FormEditeazaTitlu = (props) => {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [alert, setAlert] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const existingTitles = [];
    props.data.forEach((item) => {
        existingTitles.push(item.title);
    });

    const onTitluChange = (titlu) => {
        existingTitles.some((element) => { return element === titlu })
            ? setInvalid(true)
            : setInvalid(false);
        formik.setFieldValue("titlu", titlu, !!formik.touched.titlu);
    };
    const formik = useFormik({
        initialValues: {
            titlu: props.selected.title
        },
        validationSchema: Yup.object().shape({
            titlu: Yup
                .string()
                .required("Invitația trebuie să aibe un titlu!")
                .trim()
                .min(5, "Titlul invitației trebuie să conțină minim 5 de caractere!")
                .max(30, "Titlul invitației trebuie să conțină maxim 30 de caractere!")
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            // If title exists
            if (existingTitles.some((element) => { return element === values.titlu })) {
                setInvalid(true);
            }
            // If title does not exist
            else {
                setInvalid(false);
                setIsSaving(true);
                await updateInvitationTitle(props.uid, props.selected.invitationType, props.selected.iid, values.titlu)
                    .then(() => {
                        props.updateTitleState(values.titlu);
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificare salvată",
                            description: "Titlul a fost salvat!",
                        });
                        setSuccess(true);
                        setTimeout(() => {
                            dispatch(modalActions.close());
                        }, 750);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            }
        },
    });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <TextInput
                        label='Titlu invitație'
                        value={formik.values.titlu}
                        changeHandler={onTitluChange}
                        error={formik.errors.titlu || (invalid && 'Există o altă invitație cu acest titlu!')}
                    />
                    <Button
                        id={classes.submitButton}
                        type='submit'
                        variant='contained'
                    >
                        Modifică
                    </Button>
                </form>
            )}
        </div>);
}

export default FormEditeazaTitlu;