import classes from '../SettingsMenu.module.scss';

import { useContext } from "react";
import InvitatiileMeleContext from '../../../../../../context/invitatiile-mele-context';
import { useDispatch } from "react-redux";
import { modalActions } from '../../../../../../store/modal-slice';
import MenuItem from '@mui/material/MenuItem';
import Pachete from '../../../../../Invitatii/Components/Pachete/Pachete';

const CumparaRaspunsuri = () => {
    const ctx = useContext(InvitatiileMeleContext);
    const dispatch = useDispatch();
    const handleCumparaRaspunsuri = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Alege pachetul dorit",
                modalContent:
                    <Pachete
                        iid={ctx.selected.iid}
                    />,
            })
        );
    }

    return (
        <MenuItem
            className={classes['menu-item']}
            onClick={handleCumparaRaspunsuri}
        >
            <i className={`fas fa-dollar-sign ${classes.icon}`}></i> Cumpără răspunsuri
        </MenuItem>
    );
}

export default CumparaRaspunsuri;