import classes from './Classes.module.scss';

import FormControl from "@mui/material/FormControl";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const DropdownWithOE = (props) => {
    const { notFullWidth, dropdownValues, label, value, placeholder, changeHandler } = props;

    const inputChangeHandler = (event) => {
        changeHandler(event.target.value);
    };
    const autocompleteChangeHandler = (event) => {
        changeHandler(event.target.outerText);
    }
    return (
        <FormControl
            fullWidth={!notFullWidth}
            component='fieldset'
            className={classes.formFieldWrapper}
        >
            <Autocomplete
                id={label.replaceAll(" ", "")}
                className={classes.autocomplete}
                freeSolo
                value={value}
                onChange={autocompleteChangeHandler}
                options={dropdownValues}
                renderInput={(params) => <TextField
                    {...params}
                    label={label}
                    value={value}
                    placeholder={placeholder}
                    onChange={inputChangeHandler}
                />}
            />
        </FormControl>
    );
}

export default DropdownWithOE;