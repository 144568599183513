import classes from '../Result.module.scss';

import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import DeleteModal from './DeleteModal/DeleteModal';
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const DeleteInvitation = (props) => {
    const dispatch = useDispatch();
    const openDeleteModal = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Ești sigur?",
                modalContent: <DeleteModal iid={props.iid} handleData={props.handleData} />,
            })
        );
    }
    return (
        <Tooltip TransitionComponent={Zoom} title="Șterge invitație" arrow  placement="right">
            <div className={classes.icon} onClick={openDeleteModal}>
                <i className="fas fa-trash-alt"></i>
            </div>
        </Tooltip>
    );
}

export default DeleteInvitation;