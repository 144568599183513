import classes from './Raspunsuri.module.scss';

import TabelNunta from './Tabele/TabelNunta';
import TabelAniversare from './Tabele/TabelAniversare';

const Raspunsuri = (props) => {
    const { answers, invitationType } = props;
    return (
        <div className={classes.raspunsuri}>
            {answers && ['n1', 'b1', 'nb1'].includes(invitationType) && (
                <TabelNunta answers={answers} />
            )}
            {answers && ['a1'].includes(invitationType) && (
                <TabelAniversare answers={answers} />
            )}
            {!answers && <p>Nu avem încă răspunsuri la această invitație!</p>}
        </div>
    );
}

export default Raspunsuri;