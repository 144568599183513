import classes from './Admin.module.scss';

import { useState } from 'react';
import AdminContext from "../../context/admin-context";
import ListaOptiuni from './ListaOptiuni/ListaOptiuni';
import Finder from '../../Components/Admin/Finder/Finder';
import CreateAdmin from '../../Components/Admin/CreateAdmin/CreateAdmin';
import Paper from '@mui/material/Paper';
import Grid from '@mui/system/Unstable_Grid';

const Admin = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleSelectedTab = (value) => {
        setSelectedTab(value);
    }
    return (
        <AdminContext.Provider value={{
            selectedTab,
            handleSelectedTab
        }}>
            <div className='page-wrapper'>
                <div className={`page-content ${classes['control-panel']}`}>
                    <h1>Control panel</h1>
                    <ListaOptiuni />
                    <Paper
                    sx={{bgcolor: '#71caed'}}
                        className={classes['invitatii-wrapper']}
                        elevation={2}
                    >
                        <Grid className={classes.grid} container>
                            <div className={classes['detalii-invitatie']}>
                                {selectedTab === 0 &&
                                    <>
                                        <h5 className={classes.title}>Caută invitație</h5>
                                        <Finder />
                                    </>
                                }
                                {selectedTab === 1 &&
                                    <>
                                        <h5 className={classes.title}>Creează admin</h5>
                                        <CreateAdmin />
                                    </>
                                }
                            </div>
                        </Grid>
                    </Paper>
                </div>
            </div>
        </AdminContext.Provider>
    );
};

export default Admin;
