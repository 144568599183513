import classes from '../invitatii-globals.module.scss';

const Footer = (props) => {
    const { slide1Updated, slide2Updated, saveDataHandler } = props;
    return (
        <div className={classes.footer}>
                    {slide1Updated && slide2Updated && (
                        <div
                            className={classes.content}
                            role='button'
                            onClick={saveDataHandler}
                        >
                            <i className='fas fa-hand-point-right'></i>
                            <h1>
                                Îți place cum arată!? Click aici pentru a continua.
                            </h1>
                            <i className='fas fa-hand-point-right'></i>
                        </div>
                    )}
                    {(!slide1Updated || !slide2Updated) && (
                        <div className={classes.content}>
                            <h1>
                                Pentru a putea continua, întâi editează invitația!
                            </h1>
                            <p></p>
                        </div>
                    )}
                </div>
    );
}

export default Footer;
