import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div className='page-wrapper'>
            <div className='page-content'>
                <h1>This is the home page</h1>
                <br />
                <Link to='about'>Click to view our about page</Link>
                <br />
                <br />
                <Link to='contact'>Click to view our contact page</Link>
                <br />
                <br />
                <Link to='invitatii/nunta/n1'>Invitație Nuntă 1</Link>
                <br />
                <br />
                <Link to='invitatii/aniversare/a1'>Invitație Aniversare 1</Link>
                <br />
                <br />
                <Link to='invitatii/botez/b1'>Invitație Botez 1</Link>
            </div>
        </div>
    );
};

export default Home;
