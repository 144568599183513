import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    uid: null,
    isAdmin: false,
    profile: {
      email: undefined,
      first_name: undefined,
      last_name: undefined,
      user_created: undefined,
    },
  },
  reducers: {
    loginUser(state, action) {
      state.uid = action.payload.uid;
      state.isAdmin = action.payload.isAdmin;
      state.profile.email = action.payload.profile?.email;
      state.profile.first_name = action.payload.profile?.first_name;
      state.profile.last_name = action.payload.profile?.last_name;
      state.profile.user_created = action.payload.profile?.user_created;
    },
    logoutUser(state) {
      state.uid = null;
      state.isAdmin = false;
      state.profile.email = undefined;
      state.profile.first_name = undefined;
      state.profile.last_name = undefined;
      state.profile.user_created = undefined;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
