import classes from "./Edit.module.scss";

import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const EditInvitatie = (props) => {
  return (
    <span
      className={`${classes["editeaza-invitatie"]}`}
      onClick={props.openModal}
    >
      <Tooltip TransitionComponent={Zoom} title={props.tooltipText} arrow>
        <i className='fas fa-edit'></i>
      </Tooltip>
    </span>
  );
};

export default EditInvitatie;
