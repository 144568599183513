import { firestore, functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

import { doc, getDoc, updateDoc, } from "firebase/firestore";

// Check if the URL is available
export const checkIfUrlExists = async (url) => {
    const checkIfUrlExistsFunction = httpsCallable(functions, 'checkIfUrlExists');
    return checkIfUrlExistsFunction({url})
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return new Error(err);
        });
};

// Check if voucher exists or it was already used
export const checkIfVoucherExist = async (voucher, uid, iid) => {
    const checkIfVoucherExistFunction = httpsCallable(functions, 'checkIfVoucherExist');
    return checkIfVoucherExistFunction({voucher, uid, iid})
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return new Error(err);
        });
};

// Save or update invitation data
export const saveOrUpdateInvitationData = async (
    uid,
    invitationType,
    url,
    invitationObject,
    pending,
    title
) => {
    const saveOrUpdateInvitationData = httpsCallable(functions, 'saveUpdateInvitation');
    return saveOrUpdateInvitationData({
        uid,
        invitationType,
        url,
        invitationObject,
        pending,
        title
    })
        .then(() => {
            return;
        })
        .catch((err) => {
            return new Error(err);
        });
}

// Retrieve invitation data
export const fetchInvitationData = async (invitationId, invitationType, uid) => {
    const userRef = doc(firestore, `users/${uid}`),
        user = await getDoc(userRef),
        userData = user.data(),
        data = userData.invitatii[invitationType][invitationId];

    return data;
}

// Fetch User invitations
export const fetchUserInvitations = async (uid) => {
    const userRef = doc(firestore, `users/${uid}`),
        user = await getDoc(userRef),
        userData = user.data(),
        invitatii = userData?.invitatii,
        invitesLeft = userData?.invites_left,
        allInvites = [];
        for (const invType in invitatii) {
            for (const iid in invitatii[invType]) {
                allInvites.push({
                    iid,
                    invitationType: invType,
                    title: invitatii[invType][iid]['title'],
                    invitesLeft: invitesLeft ? invitesLeft[iid] : 0
                });
            }
        }

    return allInvites;
}

// Fetch invites left
export const fetchInvitesLeft = async (uid, iid) => {
    const userRef = doc(firestore, `users/${uid}`),
    user = await getDoc(userRef),
        userData = user.data(),
        invitesLeft = userData?.invites_left;
    
    return invitesLeft[iid];
}

// Update invitation title
export const updateInvitationTitle = async (uid, invType, iid, title) => {
    const userRef = doc(firestore, `users/${uid}`),
        user = await getDoc(userRef),
        userData = user.data(),
        invitatii = userData?.invitatii;
    invitatii[`${invType}`][`${iid}`].title = title;
    await updateDoc(userRef, { invitatii });
};

// Save invitation answer
export const saveInvitationAnswer = async (uid, invId, answer) => {
    const saveAnswer = httpsCallable(functions, 'saveAnswer');
    return saveAnswer({
        uid,
        invId,
        answer
    })
        .then(() => {
            return;
        })
        .catch((err) => {
            return new Error(err);
        });
}

// Fetch invitation answers
export const fetchAnswerData = async (uid, invId) => {
    const userRef = doc(firestore, `users/${uid}`),
        user = await getDoc(userRef),
        userData = user.data(),
        invites_left = userData?.invites_left ? userData?.invites_left[invId] : 0,
        raspunsuri = userData?.raspunsuri ? userData?.raspunsuri[invId] : null;
    return ({ invites_left, raspunsuri });
}

// Fetch pending invitations
export const fetchPendingInvitations = async (uid) => {
    const checkPendingInv = httpsCallable(functions, 'checkPendingInv');
    return checkPendingInv({uid})
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return new Error(err);
        });
}
